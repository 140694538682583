const clients = {
    add: 'Додати клієнта',
    title: 'Клієнти',
    client: 'Клієнт',
    company: 'Компанія',
    media: 'Медіа файли',
    adv: 'Рекламна компанія',
    contract: 'Договір',
    director: 'Директор',
    plan: 'Планові покази',
    hour: 'Покази',
    contents: 'Зміст',
    devices: 'Пристрої',
};

export default clients;