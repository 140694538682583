import { baseApi } from '../baseApi';
export const airtimesApi = baseApi.injectEndpoints({
  reducerPath: 'airtimesApi',
  tagTypes: ['airtimes'],
  endpoints: builder => ({
    getAllAirtimes: builder.query({
      query: ({ projectId, page = 1, limit = 50, search = '' }) =>
        `airtimes?projectId=${projectId}&page=${page}&limit=${limit}&search=${search}`,
      providesTags: ['airtimes'],
    }),
    getAirtime: builder.query({
      query: ({ projectId, id }) => `airtimes/${id}?projectId=${projectId}`,
      providesTags: ['airtimes', 'airtimeItems'],
    }),
    getAirtimeByDevice: builder.query({
      query: ({ projectId, deviceId }) =>
        `airtimes/devices?projectId=${projectId}&deviceId=${deviceId}`,
      providesTags: ['airtimes'],
    }),
    updateAirtime: builder.mutation({
      query({ id, body, projectId }) {
        return {
          url: `airtimes/${id}?projectId=${projectId}`,
          method: 'PUT',
          body,
        };
      },

      invalidatesTags: ['airtimes'],
    }),
    updateAirtimeDevices: builder.mutation({
      query({ id, projectId, devices }) {
        return {
          url: `airtimes/${id}/devices?projectId=${projectId}`,
          method: 'PATCH',
          body: {
            devices,
          },
        };
      },
      invalidatesTags: ['airtimes'],
    }),

    addAirtime: builder.mutation({
      query({ body, projectId }) {
        return {
          url: `airtimes?projectId=${projectId}`,
          method: 'POST',
          body,
        };
      },

      invalidatesTags: ['airtimes'],
    }),
    deleteAirtime: builder.mutation({
      query({ id, projectId }) {
        return {
          url: `airtimes/${id}?projectId=${projectId}`,
          method: 'DELETE',
        };
      },
      invalidatesTags: ['airtimes'],
    }),
  }),
});
export const {
  useGetAllAirtimesQuery,
  useGetAirtimeQuery,
  useLazyGetAirtimeByDeviceQuery,
  useLazyGetAirtimeQuery,
  useUpdateAirtimeMutation,
  useUpdateAirtimeDevicesMutation,
  useAddAirtimeMutation,
  useDeleteAirtimeMutation,
} = airtimesApi;
