const airtimes = {
  create: 'Створити ефір',
  title: 'Ефір',
  title2: 'Гарячі плейлисти',
  title3: 'Програматик',
  all: 'Вибрати все',
  add: 'Додати медіа',
  new: 'Новий ефір',
  mainFolder: 'Тека ефіру',
  isSynchronized: 'Синхронізувати',
  quota: 'Основна квота',
  folder: 'Тека кліпів',
  date: 'Дата показу',
  time: 'Час показу',
  order: 'Порядок відтворення',
  repeat: 'Повторити після',
  play: 'Відтворювати лише цей файл',
  number: 'Кількість показів',
  without: 'Максімальна к-ть показів (12)',
  content: 'Зміст',
  start: 'Початок',
  end: 'Завершення',
  hh: 'гг:хх',
  airtimeIn: 'Ефірний час у',
  hour: 'година',
  free: 'Вільно',
  clips: 'Кліпи',
  period: 'Період показу',
  export: 'Експорт',
  publish: 'Опублікувати',
  alertTitle: 'Підтвердіть публікацію',
  alertQuestion:
    'Ви впевнені, що хочете опублікувати? Цю дію неможливо скасувати!',
  published: 'Успішно опубліковано',
  selectAll: 'Вибрати всі',
  deselectAll: 'Зняти всі',
  beforeunload:
    'Ви впевнені, що хочете залишити цю сторінку? Неопубліковані зміни буде втрачено!',
  isAlreadyInAirtime: ' вже додано в ефір',
  areAlreadyInAirtime: ' вже додано в ефір',
  offline: 'Пристрій офлайн',
  showInfo: 'Детальніше',
  exportXSLX: 'Експорт в XSLX',
  updated: 'Оновлено',
  notUpdated: 'Не оновлено',
  newTime: '+ додати час показу',
  panelSize: 'Розмір панелі',
  panelPosition: 'Розташування панелі',
  expired: 'Період трансляції завершений',
  expiredSoon: 'Період трансляціїї скоро завершиться',
  searchByTag: 'Пошук по тегу',
  absentPlaylist: 'Плейлист відсутній',
  notSynchronized: 'Тривалість ефіру не співпадає',
  noDevices: ' не призначено в ефір на жоден пристрій',
};

export default airtimes;
