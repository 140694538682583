const toasts = {
  error: 'An error occurred, please try again later',
  copy: 'Copy completed',
  created: ' was created',
  updated: ' was updated',
  added: ' was added',
  deleted: 'Deleted successfully',
  oops: 'Oops...',
  userError: 'You cannot delete yourself!',
  serverError: 'Server is not available, please try again later',
  folderError: 'Folder cannot be deleted because it is not empty',
  selected: 'Selected all',
  deselected: 'Deselected all',
  coincide: 'Time coincides!',
  emptyField: 'Please fill in all field',
  emptyTimeOptions: 'Please add show time',
  updatePlayerError: 'The player is not online or an error has occurred',
  updatePlayerStatusError: 'Error while updating the player',
  updatePlayerUpdateAvailable:
    'A new version is available and will be updated to v.',
  updatePlayerUpdateNotAvailable: 'Your application is up-to-date',
};

export default toasts;
