import { MenuItem, Select, styled } from "@mui/material";
import { RiArrowDownSLine } from "react-icons/ri";

export const Wrapper = styled('div')(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  gap: theme.spacing(1.5),

  justifyContent: 'flex-end',
}));

export const Icon = styled(RiArrowDownSLine)(({ theme }) => ({
  fill: theme.palette.text.secondary,

  [theme.breakpoints.up('laptop')]: {
    fill: theme.palette.text.primary,
  },
}));

export const CustomSelector = styled(Select)(({ theme }) => ({
  ...theme.typography.overline,
  color: theme.palette.text.secondary,
  fieldset: {
    border: 'none',
  },

  background: 'transparent',

  '& .MuiSelect-select': {
    padding: 0,
    outline: 'none',

    '&.MuiSelect-outlined': {
      paddingRigth: '24px',
    },
  },

  '&.MuiInputBase-root': {
    borderRadius: 0,
    padding: theme.spacing(1.375),
  },

  borderBottom: `${theme.borderLine.primary} ${theme.palette.secondary.medium}`,

  '&:hover': {
    backgroundColor: theme.palette.primary.veryLight,
  },

  [theme.breakpoints.up('laptop')]: {
    ...theme.typography.body1,
    color: theme.palette.text.primary,
  },
}));

export const CustomOption = styled(MenuItem)(({ theme }) => ({
    '&.MuiMenuItem-root': {
        '&[aria-selected=true]': {
            backgroundColor: theme.palette.primary.veryLight,
        },
    }
}));