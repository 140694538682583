const widgets = {
    format: 'Формат',
    forecasts: 'Прогноз',
    forecastCount: 'К-ть прогнозів',
    create: 'Створити віджет',
    weatherObject: 'Погода',
    title: 'Віджети',
    widget: 'Віджет',
    devices: 'Пристрої',
    symbol: 'Символ',
    decimals: 'Знаків після ,',
    width: 'Ширина',
    height: 'Висота',
    position: 'Позиція',
    orientation: 'Орієнтація',
    fName: 'Назва шрифту',
    fSize: 'Розмір шрифту',
    fStyle: 'Стиль шрифту',
    location: 'Локація',
    folder: 'Папка з іконками',
    image: 'Зображення',
    language: 'Мова',
    color: 'Колір',
    text: 'Текст',
    active: 'Активний',
    forecastType: 'Тип прогнозу',
};

export default widgets;