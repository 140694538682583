import { useSelector } from "react-redux";
import {
  selectCurrentUser,
  selectIsLoggedIn,
  selectIsRefreshing,
  selectCurrentProject,
} from "../redux/api/backend/auth/authSelector";

export const useAuth = () => {
  const isLoggedIn = useSelector(selectIsLoggedIn);
  const isRefreshing = useSelector(selectIsRefreshing);
  const user = useSelector(selectCurrentUser);
  const currentProject = useSelector(selectCurrentProject);

  return {
    isLoggedIn,
    isRefreshing,
    user,
    currentProject,
  };
};
