const validation = {
    required: ' is a required field',
    minValue: 'Minimum value:',
    maxValue: 'Maximum value:',
    minSymbols: 'Minimum number of symbols:',
    maxSymbols: 'Maximum number of symbols:',
    template: 'Must match the template',
    later: 'End date must be later than start date',
    laterTime: 'End time must be later than start time',
    color: 'Invalid color format',
    select: 'Select a value from the list',
};

export default validation;