import { baseApi } from '../baseApi';

export const clientsApi = baseApi.injectEndpoints({
  reducerPath: 'clientsApi',
  tagTypes: ['clients'],
  endpoints: builder => ({
    getAllClients: builder.query({
      query: ({ projectId, page = 1, limit = 50, search = '' }) =>
        `clients?projectId=${projectId}&page=${page}&limit=${limit}&search=${search}`,
      providesTags: ['clients'],
    }),
    getClient: builder.query({
      query: ({ projectId, id }) => `clients/${id}?projectId=${projectId}`,
      providesTags: ['clients'],
    }),
    addClient: builder.mutation({
      query({ body, projectId }) {
        return {
          url: `clients?projectId=${projectId}`,
          method: 'POST',
          body,
        };
      },
      invalidatesTags: ['clients'],
    }),
    copyClient: builder.mutation({
      query({ id, projectId }) {
        return {
          url: `clients/copy/${id}?projectId=${projectId}`,
          method: 'POST',
          body: {},
        };
      },

      invalidatesTags: ['clients'],
    }),
    updateClient: builder.mutation({
      query({ id, body, projectId }) {
        return {
          url: `clients/${id}?projectId=${projectId}`,
          method: 'PUT',
          body,
        };
      },
      invalidatesTags: ['clients'],
    }),
    updateClientDevices: builder.mutation({
      query({ id, projectId, devices }) {
        return {
          url: `clients/${id}/devices?projectId=${projectId}`,
          method: 'PATCH',
          body: {
            devices,
          },
        };
      },
      invalidatesTags: ['clients'],
    }),
    deleteClient: builder.mutation({
      query({ id, projectId }) {
        return {
          url: `clients/${id}?projectId=${projectId}`,
          method: 'DELETE',
        };
      },
      invalidatesTags: ['clients'],
    }),
  }),
});

export const {
  useGetAllClientsQuery,
  useLazyGetClientQuery,
  useAddClientMutation,
  useUpdateClientMutation,
  useCopyClientMutation,
  useDeleteClientMutation,
  useUpdateClientDevicesMutation,
} = clientsApi;
