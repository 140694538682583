const devices = {
  create: 'Create group',
  new: 'New device',
  title: 'Devices',
  title2: 'Groups',
  key: 'Activation key',
  updatePlayer: 'Player update',
  playerVersion: 'Player version',
  location: 'Location',
  position: 'Position',
  group: 'Group',
  screens: 'Screens',
  active: 'ACTIVE',
  notWorking: 'NOT WORKING',
  renovation: 'RENOVATION',
  noGroup: 'No group',
  time: 'Work time',
  sleepOn: 'Sleep on',
  sleepOff: 'Sleep off',
  shutDown: 'ShutDown',
  online: 'Online',
  lastUpdate: 'Last update',
  absent: 'Absent',
  notUpdated: 'Not updated',
  volume: 'Volume',
  workingHours: 'Working hours',
  volumeSchedule: 'Volume schedule',
  addNew: '+ add',
  days: 'All days',
  hours: 'Hours',
};

export default devices;
