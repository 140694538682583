const common = {
  search: 'Пошук',
  delete: 'Видалити',
  edit: 'Редагувати',
  copy: 'Копіювати',
  items: 'Ел-тів на стор.',
  actions: 'Дії',
  name: 'Назва',
  type: 'Тип',
  error: 'Помилка',
  status: 'Статус',
  upload: 'Завантажити',
  changeLang: 'Змінити мову',
  of: 'з',
  moreThan: 'більш ніж',
  showColumn: 'Показати стовпчик',
  hideColumn: 'Сховати стовпчик',
  manageColumns: 'Керування стовпчиками',
  findColumn: 'Знайти стовпчик',
  columnTitle: 'Заголовок стовпчика',
  hideAll: 'Сховати всі',
  showAll: 'Показати всі',
  all: 'Всі',
  basicInfo: 'Основна інформація',
};

export default common;
