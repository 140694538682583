import React, { Suspense } from 'react';
import { createRoot } from 'react-dom/client';
import { Provider } from 'react-redux';
import { store, persistor } from './shared/redux/store';
import { PersistGate } from 'redux-persist/integration/react';
import { ThemeProvider } from '@mui/material/styles';
import { Container } from '@mui/material';
import { BrowserRouter } from 'react-router-dom';
import { ToastContainer } from 'react-toastify';
import '@fontsource/open-sans';
import '@fontsource/open-sans/600.css';
import '@fontsource/open-sans/700.css';
import '@fontsource/open-sans/400.css';

import { theme } from 'shared/styled/theme/customTheme';
import App from './App';
import 'shared/styled/index.css';
import 'shared/localization/i18n';
import Loader from 'shared/components/Loader/Loader';

const container = document.getElementById('root');
const root = createRoot(container);

root.render(
  <React.StrictMode>
    <Provider store={store}>
      <PersistGate loading={null} persistor={persistor}>
        <ThemeProvider theme={theme}>
          <BrowserRouter>
            <Container
              sx={{
                position: 'relative',
                paddingX: { mobile: '20px', tablet: '20px' },
                padding: { laptop: '0', desktop: '0' },
                maxWidth: 'desktop',
                backgroundColor: 'background.paper',
              }}
            >
              <Suspense fallback={<Loader />}>
                <App />
              </Suspense>

              <ToastContainer position="top-center" autoClose={3000} />
            </Container>
          </BrowserRouter>
        </ThemeProvider>
      </PersistGate>
    </Provider>
  </React.StrictMode>
);
