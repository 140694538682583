const toasts = {
  error: 'Сталася помилка. Будь ласка, спробуйте пізніше',
  copy: 'Копіювання завершено',
  created: ' створено',
  updated: ' оновлено',
  added: ' додано',
  deleted: 'Успішно видалено',
  oops: 'Ой...',
  userError: 'Ви не можете видалити себе!',
  serverError: 'Сервер не доступний. Будь ласка, спробуйте пізніше',
  folderError: 'Папка не може бути видалена так як вона не пуста',
  selected: 'Вибрано всі',
  deselected: 'Вибір скасовано',
  coincide: 'Час збігається!',
  emptyField: 'Будь ласка, заповніть усі поля',
  emptyTimeOptions: 'Будь ласка, додайте час показу',
  updatePlayerError: 'Плеєр не в мережі або сталася помилка',
  updatePlayerStatusError: 'Помилка під час оновлення плеєра',
  updatePlayerUpdateAvailable: 'Доступна нова версія та буде оновлена до v.',
  updatePlayerUpdateNotAvailable: 'Ваш додаток актуальний',
};

export default toasts;
