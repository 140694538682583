const login = {
    title: 'Please, sign in',
    description: 'Sign in with your email address and password',
    email: 'Email address',
    password: 'Password',
    signIn: 'Sign in',
    design: 'Design created by ',
    designer: 'Victoria Vykhor',
};

export default login;