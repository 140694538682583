const buttons = {
    save: 'Save',
    delete: 'Delete',
    close: 'Close',
    cancel: 'Cancel',
    create: 'Create',
    add: 'Add',
    refresh: 'Refresh',
    undo: 'Cancel',
    redo: 'Repeat',
    clear: 'Clear',
    open: 'Open',
    next: 'Next',
    back: 'Back'
};

export default buttons;