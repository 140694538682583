import airtimes from "./airtimes";
import alert from "./alert";
import clients from "./clients";
import content from "./content";
import days from "./days";
import devices from "./devices";
import devicesForm from "./devicesForm";
import groups from "./groups";
import hotPlaylists from "./hotPlaylists";
import login from "./login";
import media from "./media";
import programmers from "./programmers";
import projects from "./projects";
import reporting from "./reporting";
import tags from "./tags";
import toasts from "./toasts";
import users from "./users";
import validation from "./validation";
import widgets from "./widgets";
import constants from "./constants";
import buttons from "./buttons";
import common from "./common";

const uk = {
    translation: {
        common,
        buttons,
        constants,
        toasts,
        alert,
        validation,
        login,
        projects,
        users,
        devices,
        days,
        groups,
        media,
        content,
        tags,
        widgets,
        devicesForm,
        airtimes,
        hotPlaylists,
        programmers,
        clients,
        reporting,
    },
};

export default uk;