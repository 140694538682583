import { baseApi } from '../baseApi';
export const usersApi = baseApi.injectEndpoints({
  reducerPath: 'usersApi',
  tagTypes: ['users'],
  endpoints: builder => ({
    getUsers: builder.query({
      query: ({ page = '1', limit = '10', search = '' }) =>
        `users?page=${page}&limit=${limit}&search=${search}`,
      providesTags: ['users'],
    }),
    getUser: builder.query({
      query: id => `users/${id}`,
      providesTags: ['users'],
    }),
    updateUser: builder.mutation({
      query({ id, body }) {
        return { url: `users/${id}`, method: 'PUT', body };
      },

      invalidatesTags: ['users', 'projects'],
    }),
    updateLanguage: builder.mutation({
      query({ id, language }) {
        return {
          url: `users/${id}/language`,
          method: 'PATCH',
          body: {
            language,
          },
        };
      },

      invalidatesTags: ['users', 'auth'],
    }),
    createUser: builder.mutation({
      query(body) {
        return { url: `users`, method: 'POST', body };
      },

      invalidatesTags: ['users', 'projects'],
    }),
    deleteUser: builder.mutation({
      query(id) {
        return { url: `users/${id}`, method: 'DELETE' };
      },
      invalidatesTags: ['users'],
    }),
  }),
});
export const {
  useUpdateUserMutation,
  useGetUsersQuery,
  useLazyGetUserQuery,
  useCreateUserMutation,
  useDeleteUserMutation,
  useUpdateLanguageMutation,
} = usersApi;
