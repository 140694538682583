const users = {
    name: 'Name',
    new: 'New user',
    title: 'Users',
    email: 'Email',
    password: 'Password',
    projects: 'Projects',
    roles: 'Roles',
};

export default users;