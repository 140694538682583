import { baseApi } from '../baseApi';
export const foldersApi = baseApi.injectEndpoints({
  reducerPath: 'foldersApi',
  tagTypes: ['folders'],
  endpoints: builder => ({
    getAllFolders: builder.query({
      query: ({ projectId, page = 1, limit = 50, search = '' }) =>
        `contents/folders?projectId=${projectId}&page=${page}&limit=${limit}&search=${search}`,
      providesTags: ['folders'],
    }),
    getFolder: builder.query({
      query: ({ projectId, id }) =>
        `contents/folders/${id}?projectId=${projectId}`,
      providesTags: ['folders'],
    }),
    updateFolder: builder.mutation({
      query({ id, body, projectId }) {
        return {
          url: `contents/folders/${id}?projectId=${projectId}`,
          method: 'PUT',
          body,
        };
      },

      invalidatesTags: ['folders'],
    }),
    addFolder: builder.mutation({
      query({ body, projectId }) {
        return {
          url: `contents/folders?projectId=${projectId}`,
          method: 'POST',
          body,
        };
      },

      invalidatesTags: ['folders'],
    }),
    deleteFolder: builder.mutation({
      query({ id, projectId }) {
        return {
          url: `contents/folders/${id}?projectId=${projectId}`,
          method: 'DELETE',
        };
      },
      invalidatesTags: ['folders'],
    }),
  }),
});
export const {
  useGetAllFoldersQuery,
  useGetLazyAllFoldersQuery,
  useGetFolderQuery,
  useLazyGetAllFoldersQuery,
  useLazyGetFolderQuery,
  useUpdateFolderMutation,
  useAddFolderMutation,
  useDeleteFolderMutation,
} = foldersApi;
