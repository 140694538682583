const widgets = {
    format: 'Format',
    forecasts: 'Forecasts',
    forecastCount: 'Forecast count',
    create: 'Create widget',
    widget: 'Widget',
    title: 'Widgets',
    symbol: 'Symbol',
    decimals: 'decimals',
    devices: 'Devices',
    width: 'Width',
    height: 'Height',
    position: 'Position',
    orientation: 'Orientation',
    fName: 'Font name',
    fSize: 'Font size',
    fStyle: 'Font style',
    language: 'Language',
    color: 'Color',
    text: 'Text',
    active: 'Active',
    forecastType: 'forecast type',
    location: 'Location',
    folder: 'Folder with icons',
    image: 'Image',
};

export default widgets;