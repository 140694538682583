export const userRoles = {
  ADMIN: 'ADMIN',
  USER: 'USER',
  MANAGER: 'MANAGER',
  PARTNER: 'PARTNER',
  SUPPORT: 'SUPPORT',
};

export const deviceStatus = {
  ACTIVE: 'ACTIVE',
  NOT_WORKING: 'NOT WORKING',
  RENOVATION: 'RENOVATION',
};

export const days = {
  MONDAY: 'monday',
  TUESDAY: 'tuesday',
  WEDNESDAY: 'wednesday',
  THURSDAY: 'thursday',
  FRIDAY: 'friday',
  SATURDAY: 'saturday',
  SUNDAY: 'sunday',
};

export const widgetType = {
  WEATHER: 'weather',
  IQAIR: 'iqair',
  CURRENCY: 'currency',
  IMAGE: 'image',
  TEXT: 'text',
  CLOCK: 'clock',
  CALENDAR: 'calendar',
};

export const weatherForecastType = {
  HOURLY: 'hourly',
  DAILY: 'daily',
  TOMORROW: 'tomorrow',
};

export const programmerType = {
  ADMIXER: 'admixer',
  SOC: 'soc',
  ALARM: 'alarm',
};

export const airtimeType = {
  STANDARD: 'standard',
  AUTO_CLIP: 'auto clip',
  PANEL: 'panel',
};

export const airtimePanelType = {
  ALL_THE_TIME: 'all the time',
  BY_CONTENT_NAME: 'by content name',
};

export const hotPlaylistType = {
  PLAYLIST: 'playlist',
  STREAM: 'stream',
};

export const fontStyle = {
  PLAIN: 'plain',
  BOLD: 'bold',
  ITALIC: 'italic',
};

export const position = {
  CENTER: 'center',
  LEFT: 'left',
  RIGHT: 'right',
};

export const orientation = {
  HORIZONTAL: 'horizontal',
  VERTICAL: 'vertical',
};

export const language = {
  UA: 'UA',
  US: 'US',
};
