import { IconButton } from '@mui/material';
import { RiLogoutBoxRLine } from 'react-icons/ri';
import { Wrapper, Name } from './UserMenu.styled';
import { useLogoutMutation } from 'shared/redux/api/backend/auth/authApi';
import { useAuth } from 'shared/hooks/useAuth';
import Line from 'shared/components/Line/Line';
export default function UserMenu() {
  const [logout] = useLogoutMutation();
  const { user } = useAuth();
  return (
    <div>
      <Line />
      <Wrapper>
        <Name variant="body1">{user.username}</Name>
        <IconButton onClick={logout} color="secondary" aria-label="logout">
          <RiLogoutBoxRLine />
        </IconButton>
      </Wrapper>
    </div>
  );
}
