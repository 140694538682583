import { useTranslation } from 'react-i18next';
import {
  CustomOption,
  CustomSelector,
  Wrapper,
  Icon,
} from './LanguageSelector.styled';
import { useAuth } from 'shared/hooks';
import { useUpdateLanguageMutation } from 'shared/redux/api/backend/users/usersApi';
import { useEffect } from 'react';

export default function LanguageSelector() {
  const { i18n, t } = useTranslation();
  const { user } = useAuth();
  const [update] = useUpdateLanguageMutation();

  useEffect(() => {
    i18n.changeLanguage(user?.language ?? 'uk');
  }, [user?.language, i18n]);

  const changeLanguage = async lng => {
    await update({ id: user._id, language: lng });
    i18n.changeLanguage(lng);
  };

  return (
    <Wrapper>
      <CustomSelector
        IconComponent={Icon}
        onChange={e => changeLanguage(e.target.value)}
        value={i18n.language}
        label={t('common.changeLanguage')}
      >
        <CustomOption value="en">ENG</CustomOption>
        <CustomOption value="uk">UKR</CustomOption>
      </CustomSelector>
    </Wrapper>
  );
}
