import { baseApi } from '../baseApi';
export const deviceApi = baseApi.injectEndpoints({
  reducerPath: 'devicesApi',
  tagTypes: ['devices'],
  endpoints: builder => ({
    getAllDevices: builder.query({
      query: ({ projectId, page = 1, limit = 50, search = '', groupId = '' }) =>
        `devices?projectId=${projectId}&page=${page}&limit=${limit}&search=${search}&groupId=${groupId}`,
      providesTags: ['devices'],
    }),
    getAllProjectsDevices: builder.query({
      query: () => `devices/all`,
      providesTags: ['devices'],
    }),
    getDevice: builder.query({
      query: ({ projectId, id }) => `devices/${id}?projectId=${projectId}`,
      providesTags: ['devices', 'groups'],
    }),
    getDevicePlaylist: builder.query({
      query: ({ projectId, deviceId }) =>
        `devices/playlist/${deviceId}?projectId=${projectId}`,
      providesTags: ['devices'],
    }),
    updateDevicePlayer: builder.mutation({
      query: ({ projectId, id }) => {
        return {
          url: `devices/${id}/update-player?projectId=${projectId}`,
          method: 'PATCH',
        };
      },
      providesTags: ['devices'],
    }),
    updateDevice: builder.mutation({
      query({ id, body, projectId }) {
        return {
          url: `devices/${id}?projectId=${projectId}`,
          method: 'PUT',
          body,
        };
      },

      invalidatesTags: ['devices', 'groups'],
    }),
    updateDeviceStatus: builder.mutation({
      query({ id, body, projectId }) {
        return {
          url: `devices/${id}/status?projectId=${projectId}`,
          method: 'PATCH',
          body,
        };
      },

      invalidatesTags: ['devices', 'groups'],
    }),
    updateDeviceGroup: builder.mutation({
      query({ id, body, projectId }) {
        return {
          url: `devices/${id}/group?projectId=${projectId}`,
          method: 'PATCH',
          body,
        };
      },

      invalidatesTags: ['devices', 'groups'],
    }),
    addDevice: builder.mutation({
      query({ body, projectId }) {
        return { url: `devices?projectId=${projectId}`, method: 'POST', body };
      },

      invalidatesTags: ['devices', 'groups'],
    }),
    copyDevice: builder.mutation({
      query({ id, projectId }) {
        return {
          url: `devices/copy/${id}?projectId=${projectId}`,
          method: 'POST',
          body: {},
        };
      },

      invalidatesTags: ['devices', 'groups'],
    }),
    deleteDevice: builder.mutation({
      query({ id, projectId }) {
        return {
          url: `devices/${id}?projectId=${projectId}`,
          method: 'DELETE',
        };
      },
      invalidatesTags: ['devices', 'groups'],
    }),
  }),
});
export const {
  useGetAllDevicesQuery,
  useGetAllProjectsDevicesQuery,
  useGetDevicePlaylistQuery,
  useLazyGetDevicePlaylistQuery,
  useLazyGetDeviceQuery,
  useGetDeviceQuery,
  useUpdateDeviceMutation,
  useUpdateDevicePlayerMutation,
  useUpdateDeviceStatusMutation,
  useUpdateGroupMutation,
  useAddDeviceMutation,
  useCopyDeviceMutation,
  useDeleteDeviceMutation,
} = deviceApi;
