const programmers = {
    create: 'Create programmer',
    new: 'New programmer',
    url: 'URL',
    token: 'Token',
    idAddress: 'Location ID',
    programmer: 'Programmer',
    alarmAir: 'Air alarm content',
    alarmAirRelease: 'All clear content',
    choose: 'Choose content',
};

export default programmers;