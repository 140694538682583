const reporting = {
  etherReference: 'Reference',
  report: 'Report',
  download: 'Download report',
  title: 'Reporting',
  address: 'Address',
  plan: 'Media plan',
  actual: 'Actual quantity',
  difference: 'Difference, %',
  client: 'Client',
  reference: 'Reporting reference',
  advertiser: 'Advertiser:',
  contentName: 'Content name:',
  term: 'Term of the advertising campaign:',
  numberPlan: 'Number of outputs according to the Media Plan',
  numberServer: 'Number of outputs according to the server',
  error: 'Error in number of outputs *',
  serverError: 'Technical server error in number of outputs **',
  errorDescr:
    '* Positive values in the table are additional outputs; negative values are outputs requiring compensation.',
  serverErrorDescr:
    '** A technical server error in the number of outputs can be no more than -3% (negative value). According to the terms of the Media Plan, the excess of this error requires compensation. Positive values do not require additional payment.',
  average: 'Average error **',
  errorReasons:
    '(!!!) Server error occurs when: updating the air for a month; unplanned power outages; planned/unplanned server replacement; technical breakdowns of server components etc.',
  from: 'from ',
  to: ' to ',
  date: 'Date:',
  ls: '_______________ LS',
  summary: 'The results of the update of the airtime ',
  state: ' as of ',
  manager: 'Responsible airtime manager: ',
  choose: 'Choose client',
  absent: 'Absent',
};

export default reporting;
