const airtimes = {
  create: 'Create airtime',
  title: 'Airtime',
  title2: 'Hot playlists',
  title3: 'Programmers',
  all: 'Select all',
  new: 'New airtime',
  quota: 'Main quota',
  folder: 'Clip folder',
  isSynchronized: 'Synchronize',
  mainFolder: 'Broadcast folder',
  date: 'Date of show',
  time: 'Time of show',
  order: 'Order play',
  repeat: 'Repeat after',
  play: 'Play only this file',
  number: 'Number of impressions',
  without: 'Without number of impressions',
  content: 'Content',
  add: 'Add media',
  start: 'Start',
  end: 'End',
  hh: 'hh:mm',
  airtimeIn: 'Airtime in',
  hour: 'hour',
  free: 'Free',
  clips: 'Clips',
  period: 'Period of show',
  export: 'Export',
  publish: 'Publish',
  alertTitle: 'Confirm publication',
  alertQuestion:
    'Are you sure you want to publish? This action cannot be undone!',
  published: 'Successfully published',
  selectAll: 'Select all',
  deselectAll: 'Deselect all',
  beforeunload:
    'Are you sure you want to leave this page? Unpublished changes will be lost!',
  isAlreadyInAirtime: ' has already been added to the airtime',
  areAlreadyInAirtime: ' have already been added to the airtime',
  offline: 'Device is offline',
  showInfo: 'Details',
  exportXSLX: 'Export in XSLX',
  notUpdated: 'Not updated',
  updated: 'Updated',
  newTime: '+ add time of show',
  panelSize: 'Panel size',
  panelPosition: 'Panel position',
  expired: 'Broadcast period has ended',
  expiredSoon: 'Broadcast period will end soon',
  searchByTag: 'Search by tag',
  absentPlaylist: 'Playlist is absent',
  notSynchronized: 'The duration of the airtime does not match',
  noDevices: ' not set to airtime to any device',
};

export default airtimes;
