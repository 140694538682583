const defaultTheme = {
shadows: {
      nav: '0px 0px 0px 0px rgba(86, 130, 69, 0.05), 8px 0px 17px 0px rgba(86, 130, 69, 0.05), 30px 0px 30px 0px rgba(86, 130, 69, 0.04), 68px 0px 41px 0px rgba(86, 130, 69, 0.02), 120px 0px 48px 0px rgba(86, 130, 69, 0.01), 188px 0px 53px 0px rgba(86, 130, 69, 0.00)',
    },
    line: {
      main: '#E8F4E0',
    },

    background: {
      paper: '#FAFFF8',
      main: '#FAFFF8',
      primary: '#EFF8EA',
      secondary: '#FFF',
      contrast: '#E8F4E0',
      dark: '#FAFFF8',
      light: '#F1F1F1',
      default: '#FFF',
    },
    text: {
      main: '#42A10C',
      primary: '#161515',
      secondary: '#161515',
      contrastText: '#FFF',
      disabled: '#7C7C7C',
    },
    success: {
      main: '#42A10C',
      dark: '#42A10C',
      light: '#42A10C',
    },

    primary: {
      main: '#42A10C',
      dark: '#42A10C',
      light: '#FFF',
      contrastText: '#333333',
      borderColor: '#42A10C',
    },

    secondary: {
      main: '#FFFFFF',
      dark: '#42A10C',
      light: '#FFF',
      contrastText: '#333333',
      borderColor: '#E8F4E0',
    },
    gradient: {
      primary: 'linear-gradient(to right, #2D8F03, #96ED32)',
    },
    error: {
      main: '#E23A44',
    },
};

export default defaultTheme;