import { baseApi } from '../baseApi';

export const reportingApi = baseApi.injectEndpoints({
  reducerPath: 'reportingApi',
  tagTypes: ['reporting'],
  endpoints: builder => ({
    reporting: builder.query({
      query: ({ clientId, startDate = '', endDate = '' }) =>
        `reporting?clientId=${clientId}&startDate=${startDate}&endDate=${endDate}`,
      providesTags: ['reporting'],
    }),
    clientReporting: builder.query({
      query: ({ clientId, startDate = '' }) =>
        `reporting/summary/client?clientId=${clientId}&startDate=${startDate}`,
      providesTags: ['reporting'],
    }),
    airtimeItemReporting: builder.query({
      query: ({ airtimeItemId, projectId }) =>
        `reporting/summary?airtimeItemId=${airtimeItemId}&projectId=${projectId}`,
      providesTags: ['reporting'],
    }),
  }),
});

export const {
  useLazyReportingQuery,
  useLazyAirtimeItemReportingQuery,
  useLazyClientReportingQuery,
} = reportingApi;
