const programmers = {
    create: 'Створити програматик',
    new: 'Новий програматик',
    url: 'URL',
    token: 'Токен',
    idAddress: 'ID локації',
    programmer: 'Програматик',
    alarmAir: 'Ролик повітряної тривоги',
    alarmAirRelease: 'Ролик відбою повітряної тривоги',
    choose: 'Виберіть ролик',
};

export default programmers;