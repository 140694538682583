const login = {
    title: 'Будь ласка, увійдіть',
    description:
        'Увійдіть за допомогою вашої електронної пошти та пароля',
    email: 'Електронна пошта',
    password: 'Пароль',
    signIn: 'Увійти',
    design: 'Дизайн створений ',
    designer: 'Вікторією Вихор',
};

export default login;