import { baseApi } from '../baseApi';

export const contentsApi = baseApi.injectEndpoints({
  reducerPath: 'contentsApi',
  tagTypes: ['contents'],
  endpoints: builder => ({
    getAllContents: builder.query({
      query: ({
        projectId,
        page = 1,
        limit = 50,
        search = '',
        folderId = '',
        type = '',
      }) =>
        `contents?projectId=${projectId}&page=${page}&limit=${limit}&search=${search}&folderId=${folderId}&type=${type}`,
      providesTags: ['contents'],
    }),
    getAllProjectsContents: builder.query({
      query: () => `contents/all`,
      providesTags: ['contents'],
    }),
    getContent: builder.query({
      query: ({ projectId, id }) => `contents/${id}?projectId=${projectId}`,
      providesTags: ['contents'],
    }),
    downloadById: builder.query({
      query: ({ projectId, id }) =>
        `contents/download/${id}?projectId=${projectId}`,
    }),
    updateContent: builder.mutation({
      query({ id, body, projectId }) {
        return {
          url: `contents/${id}/?projectId=${projectId}`,
          method: 'PUT',
          body,
        };
      },

      invalidatesTags: ['contents', 'airtimes', 'airtimeItems'],
    }),
    contentsChangeTag: builder.mutation({
      query({ tag, contents, projectId }) {
        return {
          url: `contents/tag?projectId=${projectId}`,
          method: 'PATCH',
          body: { tag, contents },
        };
      },

      invalidatesTags: ['contents', 'airtimes', 'airtimeItems'],
    }),
    uploadFile: builder.mutation({
      query: ({ file, projectId, folderId }) => {
        console.log(file);
        const formData = new FormData();
        formData.append('file', file);
        formData.append('folderId', folderId);
        return {
          url: `contents/upload?projectId=${projectId}`,
          method: 'POST',
          body: formData,
        };
      },
      invalidatesTags: ['contents', 'folders'],
    }),
    download: builder.mutation({
      query: ({ projectId, contents }) => ({
        url: `contents/download?projectId=${projectId}`,
        method: 'POST',
        body: { contents },
        responseHandler: async response => {
          if (!response.ok) return response.json();

          window.location.assign(
            window.URL.createObjectURL(await response.blob())
          );
        },
      }),
    }),
    deleteContents: builder.mutation({
      query({ contents, projectId, folderId }) {
        return {
          url: `contents?projectId=${projectId}`,
          method: 'DELETE',
          body: { contents, folderId },
        };
      },
      invalidatesTags: ['contents', 'folders'],
    }),
    deleteContent: builder.mutation({
      query({ id, projectId }) {
        return {
          url: `contents/${id}?projectId=${projectId}`,
          method: 'DELETE',
        };
      },
      invalidatesTags: ['contents', 'folders'],
    }),
  }),
});
export const {
  useGetAllContentsQuery,
  useGetAllProjectsContentsQuery,
  useLazyGetAllContentsQuery,
  useLazyGetContentQuery,
  useDownloadByIdQuery,
  useUpdateContentMutation,
  useContentsChangeTagMutation,
  useUploadFileMutation,
  useDeleteContentsMutation,
  useDeleteContentMutation,
  useDownloadMutation,
} = contentsApi;
