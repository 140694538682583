const validation = {
    required: ' є обовʼязковим полем',
    minValue: 'Мінімальне значення:',
    maxValue: 'Максимальне значення:',
    minSymbols: 'Мінімальна кількість символів:',
    maxSymbols: 'Максимальна кількість символів:',
    template: 'Має відповідати шаблону',
    later: 'Дата завершення має бути пізніше дати початку',
    laterTime: 'Час завершення має бути пізніше часу початку',
    color: 'Некоректний формат кольору',
    valid: 'Будь ласка, перевірте правильність значення поля',
    select: 'Оберіть значення зі списку',
};

export default validation;