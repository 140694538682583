import { baseApi } from '../baseApi';
export const airtimeItemsApi = baseApi.injectEndpoints({
  reducerPath: 'airtimeItemsApi',
  tagTypes: ['airtimeItems'],
  endpoints: builder => ({
    getAllAirtimeItems: builder.query({
      query: ({ projectId, airtimeId }) =>
        `airtimes/items?projectId=${projectId}&airtimeId=${airtimeId}`,
      providesTags: ['airtimeItems'],
    }),
    getAirtimeItem: builder.query({
      query: ({ projectId, id, airtimeId }) =>
        `airtimes/items/${id}?projectId=${projectId}&airtimeId=${airtimeId}`,
      providesTags: ['airtimeItems'],
    }),
    updateAirtimeItems: builder.mutation({
      query({ items, projectId, airtimeId }) {
        return {
          url: `airtimes/items?projectId=${projectId}&airtimeId=${airtimeId}`,
          method: 'PUT',
          body: { items },
        };
      },

      invalidatesTags: ['airtimeItems'],
    }),
    addAirtimeItems: builder.mutation({
      query({ items, projectId, airtimeId }) {
        return {
          url: `airtimes/items?projectId=${projectId}&airtimeId=${airtimeId}`,
          method: 'POST',
          body: { items },
        };
      },

      invalidatesTags: ['airtimeItems'],
    }),
    deleteAirtimeItems: builder.mutation({
      query({ items, projectId, airtimeId }) {
        return {
          url: `airtimes/items?projectId=${projectId}&airtimeId=${airtimeId}`,
          method: 'DELETE',
          body: { items },
        };
      },
      invalidatesTags: ['airtimeItems'],
    }),
  }),
});

export const {
  useGetAllAirtimeItemsQuery,
  useLazyGetAirtimeItemQuery,
  useUpdateAirtimeItemsMutation,
  useAddAirtimeItemsMutation,
  useDeleteAirtimeItemsMutation,
  useUpdateAirtimeItemDevicesMutation,
} = airtimeItemsApi;
