import { configureStore } from '@reduxjs/toolkit';
import { setupListeners } from '@reduxjs/toolkit/query';
import {
  persistStore,
  persistReducer,
  FLUSH,
  REHYDRATE,
  PAUSE,
  PERSIST,
  PURGE,
  REGISTER,
} from 'redux-persist';
import storage from 'redux-persist/lib/storage';
import { authApi } from './api/backend/auth/authApi';
import { authReducer } from './api/backend/auth/authSlice';
import { usersApi } from './api/backend/users/usersApi';
import { projectsApi } from './api/backend/projects/projectsApi';
import { deviceApi } from './api/backend/devices/devicesApi';
import { groupsApi } from './api/backend/devices/groupsApi';
import { foldersApi } from './api/backend/media/foldersApi';
import { contentsApi } from './api/backend/media/contentsApi';
import { widgetsApi } from './api/backend/widgets/widgetsApi';
import { airtimesApi } from './api/backend/airtimes/airtimesApi';
import { airtimeItemsApi } from './api/backend/airtimes/airtimeItemsApi';
import { hotPlaylistsApi } from './api/backend/airtimes/hotPlaylistsApi';
import { programmersApi } from './api/backend/airtimes/programmersApi';
import { clientsApi } from './api/backend/clients/clientsApi';
import { reportingApi } from './api/backend/reporting/reportingApi';
import { tagsApi } from './api/backend/media/tagsApi';

const authPersistConfig = {
  key: 'auth',
  storage,
  whitelist: ['currentProject'],
};

export const store = configureStore({
  reducer: {
    [authApi.reducerPath]: authApi.reducer,
    [usersApi.reducerPath]: usersApi.reducer,
    [projectsApi.reducerPath]: usersApi.reducer,
    [deviceApi.reducerPath]: deviceApi.reducer,
    [groupsApi.reducerPath]: groupsApi.reducer,
    [foldersApi.reducerPath]: foldersApi.reducer,
    [contentsApi.reducerPath]: contentsApi.reducer,
    [tagsApi.reducerPath]: tagsApi.reducer,
    [widgetsApi.reducerPath]: widgetsApi.reducer,
    [airtimesApi.reducerPath]: airtimesApi.reducer,
    [airtimeItemsApi.reducerPath]: airtimeItemsApi.reducer,
    [hotPlaylistsApi.reducerPath]: hotPlaylistsApi.reducer,
    [programmersApi.reducerPath]: programmersApi.reducer,
    [clientsApi.reducerPath]: clientsApi.reducer,
    [reportingApi.reducerPath]: reportingApi.reducer,
    auth: persistReducer(authPersistConfig, authReducer),
  },

  middleware: getDefaultMiddleware => [
    ...getDefaultMiddleware({
      serializableCheck: {
        ignoredActions: [FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER],
      },
    }),
    authApi.middleware,
    usersApi.middleware,
    projectsApi.middleware,
    deviceApi.middleware,
    groupsApi.middleware,
    foldersApi.middleware,
    contentsApi.middleware,
    tagsApi.middleware,
    widgetsApi.middleware,
    airtimesApi.middleware,
    airtimeItemsApi.middleware,
    hotPlaylistsApi.middleware,
    programmersApi.middleware,
    clientsApi.middleware,
    reportingApi.middleware,
  ],
});

export const persistor = persistStore(store);
setupListeners(store.dispatch);