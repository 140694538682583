import { createTheme } from '@mui/material/styles';
import palette from './palettes';
const { VITE_THEME } = import.meta.env;

export const theme = createTheme({
  typography: {
    fontFamily: ['Open Sans', 'sans-serif'].join(','),
    h1: {
      fontSize: '32px',
      fontWeight: 700,
      lineHeight: 44 / 32,
    },
    h2: {
      fontSize: '32px',
      fontWeight: 600,
      lineHeight: 44 / 32,
    },
    h3: {
      fontSize: '24px',
      fontWeight: 400,
      lineHeight: 33 / 24,
    },
    h4: {
      fontSize: '20px',
      fontWeight: 600,
      lineHeight: 27 / 20,
    },
    h5: {
      fontSize: '18px',
      fontWeight: 600,
      lineHeight: 25 / 18,
    },
    h6: {
      fontSize: '24px',
      fontWeight: 600,
      lineHeight: 33 / 24,
    },
    title1: {
      fontSize: '32px',
      fontWeight: 400,
      lineHeight: 44 / 32,
    },
    title2: {
      fontSize: '24px',
      fontWeight: 700,
      lineHeight: 33 / 24,
    },
    subtitle1: {
      fontSize: '16px',
      fontWeight: 600,
      lineHeight: 22 / 16,
    },
    subtitle2: {
      fontSize: '14px',
      fontWeight: 600,
      lineHeight: 19 / 14,
    },
    body1: {
      fontSize: '20px',
      fontWeight: 400,
      lineHeight: 27 / 20,
    },
    body2: {
      fontSize: '18px',
      fontWeight: 400,
      lineHeight: 25 / 18,
    },
    overline: {
      fontSize: '16px',
      fontWeight: 400,
      lineHeight: 22 / 16,
      textTransform: 'none',
    },
    caption: {
      fontSize: '14px',
      fontWeight: 400,
      lineHeight: 19 / 14,
      textTransform: 'none',
    },
  },
  palette: palette[VITE_THEME ?? 'default'],
  breakpoints: {
    values: {
      mobile: 0,
      tablet: 640,
      laptop: 1024,
      desktop: 1440,
    },
  },
  spacing: 4,
  borderLine: {
    primary: '1px solid',
  },
  borderRadius: {
    primary: '10px',
    secondary: '5px',
  },
});
