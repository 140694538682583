import { baseApi } from '../baseApi';
export const authApi = baseApi.injectEndpoints({
  reducerPath: 'authApi',
  tagTypes: ['auth'],
  endpoints: builder => ({
    refresh: builder.query({
      query: () => 'users/refresh',
      providesTags: ['auth', 'projects', 'users'],
    }),
    login: builder.mutation({
      query(body) {
        return { url: `users/login`, method: 'POST', body };
      },

      providesTags: ['auth'],
    }),
    logout: builder.mutation({
      query() {
        return { url: `users/logout`, method: 'POST' };
      },
      providesTags: ['auth'],
    }),
  }),
});
export const { useLoginMutation, useRefreshQuery, useLogoutMutation } = authApi;
