import { styled, List, ListItem } from '@mui/material';
import { NavLink } from 'react-router-dom';

export const SecondaryLogo = styled('span')(({ theme }) => ({
  ...theme.typography.h4,
}));

export const PrimaryLogo = styled('span')(({ theme }) => ({
  ...theme.typography.h4,
  fontWeight: 300,
}));

export const ListLink = styled(List)(({ theme }) => ({
  marginTop: theme.spacing(5),
  marginBottom: theme.spacing(5),
  padding: 0,
  display: 'flex',
  gap: theme.spacing(2),
  flexDirection: 'column',

  [theme.breakpoints.up('laptop')]: {
    gap: theme.spacing(3.75),
  },
}));

export const LinkItem = styled(ListItem)(({ theme }) => ({
  padding: 0,

  '& svg': {
    width: '18px',
    height: '18px',

    [theme.breakpoints.up('laptop')]: {
      width: '24px',
      height: '24px',
    },
  },
}));

export const Link = styled(NavLink)(({ theme }) => ({
  display: 'flex',
  gap: theme.spacing(2.5),
  alignItems: 'center',
  padding: `${theme.spacing(1)} ${theme.spacing(7.5)}`,
  color: theme.palette.text.secondary,
  ...theme.typography.body1,
  width: '100%',
  textDecoration: 'none',
  cursor: 'pointer',
  transition: 'color 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms',
  ':hover': {
    color: theme.palette.text.main,
  },
  ':focus': {
    color: theme.palette.text.main,
  },
  '&.active': {
    borderLeft: `10px solid ${theme.palette.text.main}`,
    color: theme.palette.text.main,
    paddingLeft: theme.spacing(6.5),
    backgroundColor: theme.palette.background.main,
  },

  [theme.breakpoints.up('laptop')]: {
    ...theme.typography.h3,
  },
}));

export const Title = styled('p')(({ theme }) => ({
  display: 'flex',
  gap: theme.spacing(2.5),
}));
