import { baseApi } from '../baseApi';

export const tagsApi = baseApi.injectEndpoints({
  reducerPath: 'tagsApi',
  tagTypes: ['tags'],
  endpoints: builder => ({
    getAllTags: builder.query({
      query: ({ projectId, page = 1, limit = 50, search = '' }) =>
        `contents/tags?projectId=${projectId}&page=${page}&limit=${limit}&search=${search}`,
      providesTags: ['tags'],
    }),
    getTag: builder.query({
      query: ({ projectId, id }) =>
        `contents/tags/${id}?projectId=${projectId}`,
      providesTags: ['tags'],
    }),
    addTag: builder.mutation({
      query({ body, projectId }) {
        return {
          url: `contents/tags?projectId=${projectId}`,
          method: 'POST',
          body,
        };
      },
      invalidatesTags: ['tags'],
    }),
    updateTag: builder.mutation({
      query({ id, projectId, body }) {
        return {
          url: `contents/tags/${id}?projectId=${projectId}`,
          method: 'PUT',
          body,
        };
      },
      invalidatesTags: ['tags', 'contents', 'airtimes'],
    }),
    deleteTag: builder.mutation({
      query({ id, projectId }) {
        return {
          url: `contents/tags/${id}?projectId=${projectId}`,
          method: 'DELETE',
        };
      },
      invalidatesTags: ['tags', 'contents', 'airtimes'],
    }),
  }),
});

export const {
  useGetAllTagsQuery,
  useLazyGetAllTagsQuery,
  useGetTagQuery,
  useLazyGetTagQuery,
  useAddTagMutation,
  useUpdateTagMutation,
  useDeleteTagMutation,
} = tagsApi;
