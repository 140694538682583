const common = {
  search: 'Search',
  delete: 'Delete',
  edit: 'Edit',
  copy: 'Copy',
  items: 'Items per page',
  actions: 'Actions',
  name: 'Name',
  type: 'Type',
  error: 'Error',
  status: 'Status',
  upload: 'Upload',
  changeLang: 'Change language',
  of: 'of',
  moreThan: 'more than',
  showColumn: 'Show column',
  hideColumn: 'Hide column',
  manageColumns: 'Manage columns',
  findColumn: 'Find column',
  columnTitle: 'Column title',
  hideAll: 'Hide all',
  showAll: 'Show all',
  all: 'All',
  basicInfo: 'Basic information',
};

export default common;
