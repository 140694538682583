const constants = {
  userRoles: {
    ADMIN: 'Адміністратор',
    USER: 'Користувач',
    MANAGER: 'Менеджер',
    PARTNER: 'Партнер',
    SUPPORT: 'Підтримка',
  },
  deviceStatus: {
    ACTIVE: 'Активний',
    'NOT WORKING': 'Не працює',
    RENOVATION: 'На реконструкції',
  },
  widgetType: {
    weather: 'Погода',
    iqair: 'Якість повітря',
    currency: 'Валюта',
    image: 'Зображення',
    text: 'Текст',
    clock: 'Годинник',
    calendar: 'Календар',
  },
  airtimeType: {
    standard: 'Стандарт',
    'auto clip': 'Міксувати музикою',
    panel: 'Панель',
  },
  hotPlaylistType: {
    playlist: 'Плейлист',
    stream: 'Потік',
  },
  fontStyle: {
    plain: 'Звичаний',
    bold: 'Жирний',
    italic: 'Курсив',
  },
  position: {
    center: 'Центр',
    left: 'Ліворуч',
    right: 'Праворуч',
  },
  orientation: {
    horizontal: 'Горизонтальна',
    vertical: 'Вертикальна',
  },
  language: {
    UA: 'Українська',
    US: 'Англійська',
  },
  programmerType: {
    admixer: 'Admixer',
    soc: 'Бот соціальних мереж',
    alarm: 'Повітряна тривога',
  },
  forecastType: {
    hourly: 'Погодинно',
    daily: 'Щодня',
    tomorrow: 'На завтра',
  },
};

export default constants;
