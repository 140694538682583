const content = {
    upload: 'Завантажити медіа',
    uploaded: 'Завантажено',
    title: 'Медіа',
    tag: 'Тег',
    size: 'Розмір',
    duration: 'Тривалість',
    resolution: 'Розширення',
    browse:
        'Перетягніть ваші файли або <span class="filepond--label-action">Огляд</span>',
    selected: ' медіа вибрано',
    delete: 'Видалити',
    change: 'Змінити тег',
    download: 'Завантажити',
    done: 'Виконано ',
    items: ' елемент(ів)',
    updated: 'Тег оновлено',
    formatError: 'Невідповідний формат файлу',
    resolutionError: 'Неправильна роздільна здатність',
    uploading: 'Вивантаження',
    complete: 'Вивантаження завершено',
    canceled: 'Вивантаження скасовано',
    cancel: 'натисніть, щоб відмінити',
    retry: 'натисніть, щоб повторити спробу',
    noTag: 'без тегу',
    filterByTag: 'Фільтр по тегу',
};

export default content;