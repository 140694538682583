const hotPlaylists = {
    create: 'Створити плейлист',
    new: 'Новий плейлист',
    playlist: 'Плейлист',
    streamUrl: 'Посилання',
    days: 'Дні показу',
    start: 'Початок показу',
    end: 'Кінець показу',
    empty: 'Будь ласка, оберіть хоча б один медіа файл',
    file: 'Файл',
    deviceName: 'Назва пристрою',
    date: 'Дата',
};

export default hotPlaylists;