import { Navigate, useLocation } from 'react-router-dom';
import { useAuth } from 'shared/hooks/useAuth';
import PropTypes from 'prop-types';
export const RestrictedRoute = ({ component: Component, redirectTo = '/' }) => {
  const { isLoggedIn } = useAuth();
  const location = useLocation();

  return isLoggedIn ? (
    <Navigate to={redirectTo} state={{ from: location.pathname }} />
  ) : (
    <Component />
  );
};

RestrictedRoute.propTypes = {
  redirectTo: PropTypes.string,
  component: PropTypes.any,
};