import { styled, Toolbar } from "@mui/material";
export const SecondaryLogo = styled("span")(({theme}) => ({
  ...theme.typography.h4,
}));

export const PrimaryLogo = styled("span")(({ theme }) => ({
  ...theme.typography.h4,
  fontWeight: 300,
}));

export const LogoBox = styled(Toolbar)(({ theme }) => ({
  gap: theme.spacing(2.5),
}))
