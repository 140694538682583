import { styled, Box, Drawer, Toolbar, AppBar } from '@mui/material';
import IconButton from '@mui/material/IconButton';
const drawerWidth = 240;

export const ContainerBox = styled(Box)(({ theme }) => ({
  display: 'flex',
  height: '100vh',
  '& .MuiPaper-elevation': {
    padding: `0 ${theme.spacing(3)}`,
  },

  [theme.breakpoints.up('laptop')]: {
    '& .MuiPaper-elevation': {
      padding: 0,
    },
  },
}));

export const CustomAppBar = styled(AppBar)(({ theme }) => ({
  backgroundColor: theme.palette.background.default,
  [theme.breakpoints.up('laptop')]: {
    width: `calc(100% - ${drawerWidth}px)`,
    marginLeft: `${drawerWidth}px`,
  },
}));

export const CustomToolbar = styled(Toolbar)(({ theme }) => ({
  justifyContent: 'space-between',
  backgroundColor: theme.palette.background.default,
  [theme.breakpoints.up('laptop')]: {
    display: 'none',
  },
}));

export const CustomIconButton = styled(IconButton)(({ theme }) => ({
  color: theme.palette.text.secondary,
  marginRight: theme.spacing(4),
  [theme.breakpoints.up('laptop')]: {
    display: 'none',
  },
}));

export const NavBox = styled(Box)(({ theme }) => ({
  [theme.breakpoints.up('laptop')]: {
    width: `${drawerWidth}px`,
    flexShrink: 0,
    borderRight: 'none',
  },
  boxShadow: theme.palette.shadows.nav,
  zIndex: 1200,
}));

export const TemporaryDrawer = styled(Drawer)(({ theme }) => ({
  [theme.breakpoints.up('mobile')]: {
    display: 'block',
  },

  [theme.breakpoints.up('laptop')]: {
    display: 'none',
  },
  '& .MuiDrawer-paper': {
    boxSizing: 'border-box',
    width: `${drawerWidth}px`,
    border: 'none',
    backgroundColor: theme.palette.background.default,
  },
}));

export const PermanentDrawer = styled(Drawer)(({ theme }) => ({
  [theme.breakpoints.up('mobile')]: {
    display: 'none',
  },

  [theme.breakpoints.up('laptop')]: {
    display: 'block',
  },
  '& .MuiDrawer-paper': {
    boxSizing: 'border-box',
    width: `${drawerWidth}px`,
    top: 0,
    left: 0,
    position: 'static',
    border: 'none',
    backgroundColor: theme.palette.background.default,
  },
}));

export const MenuBox = styled(Box)(() => ({
  display: 'flex',
  height: '100vh',
  flexDirection: 'column',
  justifyContent: 'space-between',
}));

export const MainBox = styled(Box)(({ theme }) => ({
  width: '100%',
  [theme.breakpoints.up('laptop')]: {
    width: `calc(100% - ${drawerWidth}px)`,
    padding: `${theme.spacing(15)} ${theme.spacing(3.75)} ${theme.spacing(
      3.75
    )} ${theme.spacing(3.75)}`,
  },
  [theme.breakpoints.up('laptop')]: {
    padding: `${theme.spacing(9.25)} ${theme.spacing(10.5)}`,
  },
  overflow: 'hidden',
  display: 'flex',
  flexDirection: 'column',
  position: 'relative',
}));
