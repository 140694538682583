import { baseApi } from '../baseApi';
export const projectsApi = baseApi.injectEndpoints({
  reducerPath: 'projectsApi',
  tagTypes: ['projects'],
  endpoints: builder => ({
    getProjects: builder.query({
      query: ({ userId = '', page = 1, limit = 50, search = '' }) =>
        `projects?userId=${userId}&page=${page}&limit=${limit}&search=${search}`,
      providesTags: ['projects'],
    }),
    getProject: builder.query({
      query: id => `projects/${id}`,
      providesTags: ['projects'],
    }),
    updateProject: builder.mutation({
      query({ id, body }) {
        return { url: `projects/${id}`, method: 'PATCH', body };
      },

      invalidatesTags: ['projects'],
    }),
    createProject: builder.mutation({
      query(body) {
        return { url: `projects`, method: 'POST', body };
      },

      invalidatesTags: ['auth', 'projects', 'users'],
    }),
    deleteProject: builder.mutation({
      query(id) {
        return { url: `projects/${id}`, method: 'DELETE' };
      },
      invalidatesTags: ['auth', 'projects', 'users'],
    }),
  }),
});
export const {
  useUpdateProjectMutation,
  useGetProjectsQuery,
  useGetProjectQuery,
  useLazyGetProjectQuery,
  useCreateProjectMutation,
  useDeleteProjectMutation,
} = projectsApi;
