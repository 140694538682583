const days = {
    long: {
        monday: 'Понеділок',
        tuesday: 'Вівторок',
        wednesday: 'Середа',
        thursday: 'Четвер',
        friday: 'Пʼятниця',
        saturday: 'Субота',
        sunday: 'Неділя',
    },
    short: {
        monday: 'Пн',
        tuesday: 'Вт',
        wednesday: 'Ср',
        thursday: 'Чт',
        friday: 'Пт',
        saturday: 'Сб',
        sunday: 'Нд',
    },
};

export default days;