const reporting = {
  etherReference: 'Довідка',
  report: 'Звіт',
  download: 'Завантажити звіт',
  title: 'Звітність',
  address: 'Адреса',
  plan: 'Медіаплан',
  actual: 'Фактична кількість',
  difference: 'Різниця, %',
  client: 'Клієнт',
  reference: 'Звітна довідка',
  advertiser: 'Рекламодавець:',
  contentName: 'Назва контенту:',
  term: 'Термін проведення рекламної кампанії:',
  numberPlan: 'Кіл-ть виходів згідно Медіа Плану',
  numberServer: 'Кіл-ть виходів згідно сервера',
  error: 'Похибка у кіл-ті виходів *',
  serverError: 'Технічна серверна похибка у кіл-ті виходів **',
  errorDescr:
    '* Позитивні значення в таблиці - це додаткові виходи; негативні значення - це виходи, що потребують компенсації.',
  serverErrorDescr:
    '** Технічна серверна похибка у кількості виходів може становити не більше -3% (негативне значення), перевищення якої, згідно умов Медіа Плану, потребує компенсації. Позитивні значення не потребують додаткової оплати.',
  average: 'Середня похибка **',
  errorReasons:
    '(!!!) Серверна похибка виникає при: оновленні ефіру продовж місяця; позапланових відключеннях енергопостачання; плановій/позаплановій заміні сервера; технічних поломках комплектуючих сервера та ін.',
  from: 'з ',
  to: ' до ',
  date: 'Дата:',
  ls: '_______________ м.п.',
  summary: 'Підсумки оновлення ефіру ',
  state: ' станом на ',
  manager: 'Відповідальний менеджер ефіру: ',
  choose: 'Виберіть клієнта',
  absent: 'Відсутні',
};

export default reporting;
