const content = {
    upload: 'Upload media',
    uploaded: 'Uploaded',
    title: 'Media',
    tag: 'Tag',
    size: 'Size',
    duration: 'Duration',
    resolution: 'Resolution',
    browse:
        'Drag & Drop your files or <span class="filepond--label-action">Browse</span>',
    selected: ' media selected',
    delete: 'Delete',
    change: 'Change tag',
    download: 'Download',
    done: 'Done ',
    items: ' item(s)',
    updated: 'Tag updated',
    formatError: 'Invalid file format',
    resolutionError: 'The resolition is incorrect',
    uploading: 'Uploading',
    complete: 'Upload complete',
    canceled: 'Upload cancelled',
    cancel: 'tap to cancel',
    retry: 'tap to retry',
    noTag: 'no tag',
    filterByTag: 'Filter by tag',
};

export default content;