import { baseApi } from '../baseApi';
export const hotPlaylistsApi = baseApi.injectEndpoints({
  reducerPath: 'hotPlaylistsApi',
  tagTypes: ['hotPlaylists'],
  endpoints: builder => ({
    getAllHotPlaylists: builder.query({
      query: ({ projectId, page = 1, limit = 50, search = '' }) =>
        `airtimes/hot-playlists?projectId=${projectId}&page=${page}&limit=${limit}&search=${search}`,
      providesTags: ['hotPlaylists'],
    }),
    getHotPlaylist: builder.query({
      query: ({ projectId, id }) =>
        `airtimes/hot-playlists/${id}?projectId=${projectId}`,
      providesTags: ['hotPlaylists'],
    }),
    updateHotPlaylist: builder.mutation({
      query({ id, body, projectId }) {
        return {
          url: `airtimes/hot-playlists/${id}?projectId=${projectId}`,
          method: 'PUT',
          body,
        };
      },

      invalidatesTags: ['hotPlaylists'],
    }),
    updateHotPlaylistDevices: builder.mutation({
      query({ id, projectId, devices }) {
        return {
          url: `airtimes/hot-playlists/${id}/devices?projectId=${projectId}`,
          method: 'PATCH',
          body: {
            devices,
          },
        };
      },
      invalidatesTags: ['hotPlaylists'],
    }),
    updateHotPlaylistContents: builder.mutation({
      query({ id, projectId, contents }) {
        return {
          url: `airtimes/hot-playlists/${id}/contents?projectId=${projectId}`,
          method: 'PATCH',
          body: {
            contents,
          },
        };
      },
      invalidatesTags: ['hotPlaylists'],
    }),
    addHotPlaylist: builder.mutation({
      query({ body, projectId }) {
        return {
          url: `airtimes/hot-playlists?projectId=${projectId}`,
          method: 'POST',
          body,
        };
      },

      invalidatesTags: ['hotPlaylists'],
    }),
    deleteHotPlaylist: builder.mutation({
      query({ id, projectId }) {
        return {
          url: `airtimes/hot-playlists/${id}?projectId=${projectId}`,
          method: 'DELETE',
        };
      },
      invalidatesTags: ['hotPlaylists'],
    }),
  }),
});

export const {
  useGetAllHotPlaylistsQuery,
  useGetHotPlaylistQuery,
  useLazyGetHotPlaylistQuery,
  useUpdateHotPlaylistMutation,
  useAddHotPlaylistMutation,
  useDeleteHotPlaylistMutation,
  useUpdateHotPlaylistDevicesMutation,
  useUpdateHotPlaylistContentsMutation,
} = hotPlaylistsApi;
