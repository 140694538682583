const users = {
    name: 'Імʼя',
    new: 'Новий користувач',
    title: 'Користувачі',
    email: 'Електронна адреса',
    password: 'Пароль',
    projects: 'Проєкти',
    roles: 'Ролі',
};

export default users;