const hotPlaylists = {
    create: 'Create playlist',
    playlist: 'Playlist',
    new: 'New playlist',
    streamUrl: 'Stream URL',
    days: 'Days of a show',
    start: 'Start of show',
    end: 'End of show',
    empty: 'Please choose at least one media file',
    file: 'File',
    deviceName: 'Device name',
    date: 'Date',
};

export default hotPlaylists;