import PropTypes from 'prop-types';
import { LogoBox } from './Logo.styled';
const { VITE_THEME } = import.meta.env;
export default function Logo({ sx }) {
  return (
    <LogoBox sx={{ ...sx }}>
      <img
        width="100%"
        height="47px"
        src={`/${VITE_THEME ?? 'default'}-logo.svg`}
        alt="logo uvplayer"
      />
    </LogoBox>
  );
}

Logo.propTypes = {
  sx: PropTypes.object,
};