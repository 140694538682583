import { baseApi } from '../baseApi';
export const programmersApi = baseApi.injectEndpoints({
  reducerPath: 'programmersApi',
  tagTypes: ['programmers'],
  endpoints: builder => ({
    getAllProgrammers: builder.query({
      query: ({ projectId, page = 1, limit = 50, search = '' }) =>
        `airtimes/programmers?projectId=${projectId}&page=${page}&limit=${limit}&search=${search}`,
      providesTags: ['programmers'],
    }),
    getProgrammer: builder.query({
      query: ({ projectId, id }) =>
        `airtimes/programmers/${id}?projectId=${projectId}`,
      providesTags: ['programmers'],
    }),
    updateProgrammer: builder.mutation({
      query({ id, body, projectId }) {
        return {
          url: `airtimes/programmers/${id}?projectId=${projectId}`,
          method: 'PUT',
          body,
        };
      },

      invalidatesTags: ['programmers'],
    }),

    addProgrammer: builder.mutation({
      query({ body, projectId }) {
        return {
          url: `airtimes/programmers?projectId=${projectId}`,
          method: 'POST',
          body,
        };
      },

      invalidatesTags: ['programmers'],
    }),
    deleteProgrammer: builder.mutation({
      query({ id, projectId }) {
        return {
          url: `airtimes/programmers/${id}?projectId=${projectId}`,
          method: 'DELETE',
        };
      },
      invalidatesTags: ['programmers'],
    }),
    updateProgrammerStatus: builder.mutation({
      query({ id, status, projectId }) {
        return {
          url: `airtimes/programmers/${id}/status?projectId=${projectId}`,
          method: 'PATCH',
          body: {
            status,
          },
        };
      },

      invalidatesTags: ['programmers'],
    }),
    updateProgrammerDevices: builder.mutation({
      query({ id, projectId, devices }) {
        return {
          url: `airtimes/programmers/${id}/devices?projectId=${projectId}`,
          method: 'PATCH',
          body: {
            devices,
          },
        };
      },
      invalidatesTags: ['programmers'],
    }),
  }),
});
export const {
  useGetAllProgrammersQuery,
  useLazyGetProgrammerQuery,
  useGetProgrammerQuery,
  useUpdateProgrammerMutation,
  useAddProgrammerMutation,
  useDeleteProgrammerMutation,
  useUpdateProgrammerStatusMutation,
  useUpdateProgrammerDevicesMutation,
} = programmersApi;
