const constants = {
  userRoles: {
    ADMIN: 'Admin',
    USER: 'User',
    MANAGER: 'Manager',
    PARTNER: 'Partner',
    SUPPORT: 'Support',
  },
  deviceStatus: {
    ACTIVE: 'Active',
    'NOT WORKING': 'Not working',
    RENOVATION: 'Renovation',
  },
  widgetType: {
    weather: 'Weather',
    iqair: 'Iqair',
    currency: 'Currency',
    image: 'Image',
    text: 'Text',
    clock: 'Clock',
    calendar: 'Calendar',
  },
  airtimeType: {
    standard: 'Standard',
    'auto clip': 'Auto clip',
    panel: 'Panel',
  },
  hotPlaylistType: {
    playlist: 'Playlist',
    stream: 'Stream',
  },
  fontStyle: {
    plain: 'Plain',
    bold: 'Bold',
    italic: 'Italic',
  },
  position: {
    center: 'Center',
    left: 'Left',
    right: 'Right',
  },
  orientation: {
    horizontal: 'Horizontal',
    vertical: 'Vertical',
  },
  language: {
    UA: 'Ukrainian',
    US: 'English',
  },
  programmerType: {
    admixer: 'Admixer',
    soc: 'Soc',
    alarm: 'Alarm',
  },
  forecastType: {
    hourly: 'Hourly',
    daily: 'Daily',
    tomorrow: 'Tomorrow',
  },
};

export default constants;
