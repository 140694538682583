import { baseApi } from '../baseApi';
export const widgetsApi = baseApi.injectEndpoints({
  reducerPath: 'widgetsApi',
  tagTypes: ['widgets'],
  endpoints: builder => ({
    getAllWidgets: builder.query({
      query: ({ projectId, page = 1, limit = 50, search = '', type = '' }) =>
        `widgets?projectId=${projectId}&page=${page}&limit=${limit}&search=${search}&type=${type}`,
      providesTags: ['widgets'],
    }),
    getWidget: builder.query({
      query: ({ projectId, id }) => `widgets/${id}?projectId=${projectId}`,
      providesTags: ['widgets'],
    }),
    updateWidget: builder.mutation({
      query({ id, body, projectId }) {
        return {
          url: `widgets/${id}?projectId=${projectId}`,
          method: 'PUT',
          body,
        };
      },

      invalidatesTags: ['widgets'],
    }),
    updateWidgetStatus: builder.mutation({
      query({ id, status, projectId }) {
        return {
          url: `widgets/${id}/status?projectId=${projectId}`,
          method: 'PATCH',
          body: {
            status,
          },
        };
      },

      invalidatesTags: ['widgets'],
    }),
    updateWidgetDevices: builder.mutation({
      query({ id, projectId, devices }) {
        return {
          url: `widgets/${id}/devices?projectId=${projectId}`,
          method: 'PATCH',
          body: {
            devices,
          },
        };
      },
      invalidatesTags: ['widgets'],
    }),
    addWidget: builder.mutation({
      query({ body, projectId }) {
        return {
          url: `widgets?projectId=${projectId}`,
          method: 'POST',
          body,
        };
      },

      invalidatesTags: ['widgets'],
    }),
    copyWidget: builder.mutation({
      query({ id, projectId }) {
        return {
          url: `widgets/copy/${id}?projectId=${projectId}`,
          method: 'POST',
          body: {},
        };
      },

      invalidatesTags: ['widgets'],
    }),
    deleteWidget: builder.mutation({
      query({ id, projectId }) {
        return {
          url: `widgets/${id}?projectId=${projectId}`,
          method: 'DELETE',
        };
      },
      invalidatesTags: ['widgets'],
    }),
  }),
});
export const {
  useGetAllWidgetsQuery,
  useLazyGetAllWidgetsQuery,
  useLazyGetWidgetQuery,
  useUpdateWidgetMutation,
  useUpdateWidgetStatusMutation,
  useAddWidgetMutation,
  useCopyWidgetMutation,
  useDeleteWidgetMutation,
  useUpdateWidgetDevicesMutation,
} = widgetsApi;
