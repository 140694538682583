const clients = {
    add: 'Add clients',
    title: 'Clients',
    client: 'Client',
    company: 'Company',
    media: 'Media files',
    adv: 'Advertising company',
    contract: 'Contract',
    director: 'Director',
    plan: 'Impressions plan hour',
    hour: 'Impressions hour',
    contents: 'Contents',
    devices: 'Devices',
};

export default clients;