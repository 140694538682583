const buttons = {
    save: 'Зберегти',
    delete: 'Видалити',
    close: 'Закрити',
    cancel: 'Скасувати',
    create: 'Створити',
    add: 'Додати',
    refresh: 'Оновити',
    undo: 'Відмінити',
    redo: 'Повторити',
    clear: 'Очистити',
    open: 'Відкрити',
    next: 'Далі',
    back: 'Назад',
};

export default buttons;