import { Routes, Route } from 'react-router-dom';

import 'react-toastify/dist/ReactToastify.css';

import { useRefreshQuery } from 'shared/redux/api/backend/auth/authApi';
import Loader from 'shared/components/Loader/Loader';
import { Suspense } from 'react';
import router from 'router/router';

function App() {
  const { isFetching } = useRefreshQuery({}, { pollingInterval: 7200000 });

  return isFetching ? (
    <Loader />
  ) : (
    <Suspense fallback={<Loader />}>
      <Routes>{router}</Routes>
    </Suspense>
  );
}

export default App;
