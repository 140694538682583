import { baseApi } from '../baseApi';
export const groupsApi = baseApi.injectEndpoints({
  reducerPath: 'groupsApi',
  tagTypes: ['groups'],
  endpoints: builder => ({
    getAllGroups: builder.query({
      query: ({ projectId, page = 1, limit = 50, search = '' }) =>
        `devices/groups?projectId=${projectId}&page=${page}&limit=${limit}&search=${search}`,
      providesTags: ['groups', 'devices'],
    }),
    getAllProjectsGroups: builder.query({
      query: () => `devices/groups/all`,
      providesTags: ['groups', 'devices'],
    }),
    getGroup: builder.query({
      query: ({ projectId, id }) =>
        `devices/groups/${id}?projectId=${projectId}`,
      providesTags: ['groups', 'devices'],
    }),
    updateGroup: builder.mutation({
      query({ id, body, projectId }) {
        return {
          url: `devices/groups/${id}?projectId=${projectId}`,
          method: 'PUT',
          body,
        };
      },

      invalidatesTags: ['groups'],
    }),
    addGroup: builder.mutation({
      query({ body, projectId }) {
        return {
          url: `devices/groups?projectId=${projectId}`,
          method: 'POST',
          body,
        };
      },

      invalidatesTags: ['groups'],
    }),
    deleteGroup: builder.mutation({
      query({ id, projectId }) {
        return {
          url: `devices/groups/${id}?projectId=${projectId}`,
          method: 'DELETE',
        };
      },
      invalidatesTags: ['groups'],
    }),
  }),
});
export const {
  useGetAllGroupsQuery,
  useGetAllProjectsGroupsQuery,
  useLazyGetGroupQuery,
  useGetGroupQuery,
  useUpdateGroupMutation,
  useAddGroupMutation,
  useDeleteGroupMutation,
} = groupsApi;
