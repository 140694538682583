import { useAuth } from "./useAuth";
export const useHasRoles = (role) => {
  const { user } = useAuth();

  if (!user) {
    return false;
  }

  return user.roles.includes(role);
};
