import {
  MainBox,
  PermanentDrawer,
  MenuBox,
  TemporaryDrawer,
  NavBox,
  ContainerBox,
  CustomAppBar,
  CustomToolbar,
  CustomIconButton,
} from './NavBar.styled';
import { RiMenuFill } from 'react-icons/ri';
import { useState } from 'react';
import NavMenu from './NavMenu/NavMenu';
import UserMenu from './UserMenu/UserMenu';
import { Outlet } from 'react-router-dom';
import { Suspense } from 'react';
import PropTypes from 'prop-types';
import Loader from 'shared/components/Loader/Loader';
import LanguageSelector from '../LanguageSelector/LanguageSelector';

function NavBar(props) {
  const { window } = props;
  const [mobileOpen, setMobileOpen] = useState(false);

  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen);
  };

  const container =
    window !== undefined ? () => window().document.body : undefined;
  return (
    <ContainerBox>
      <CustomAppBar>
        <CustomToolbar>
          <CustomIconButton
            color="inherit"
            aria-label="open drawer"
            edge="start"
            onClick={handleDrawerToggle}
          >
            <RiMenuFill />
          </CustomIconButton>
          <LanguageSelector />
        </CustomToolbar>
      </CustomAppBar>
      <NavBox component="nav" aria-label="navigation">
        <TemporaryDrawer
          container={container}
          variant="temporary"
          open={mobileOpen}
          onClose={handleDrawerToggle}
          ModalProps={{
            keepMounted: true, // Better open performance on mobile.
          }}
        >
          <MenuBox>
            <NavMenu onClose={handleDrawerToggle} />
            <UserMenu onClose={handleDrawerToggle} />
          </MenuBox>
        </TemporaryDrawer>
        <PermanentDrawer variant="permanent" open>
          <MenuBox>
            <NavMenu />
            <UserMenu />
          </MenuBox>
        </PermanentDrawer>
      </NavBox>
      <MainBox component="main">
        <CustomToolbar />
        <Suspense fallback={<Loader />}>
          <Outlet />
        </Suspense>
      </MainBox>
    </ContainerBox>
  );
}

export default NavBar;

NavBar.propTypes = {
  window: PropTypes.node,
};
