const devices = {
  create: 'Створити групу',
  new: 'Новий пристрій',
  title: 'Пристрої',
  title2: 'Групи',
  key: 'Ключ активації',
  updatePlayer: 'Оновити плеєр',
  playerVersion: 'Версія плеєра',
  location: 'Розташування',
  position: 'Позиція',
  group: 'Група',
  screens: 'Роздільна здатність',
  active: 'АКТИВНИЙ',
  notWorking: 'НЕ ПРАЦЮЄ',
  renovation: 'РЕКОНСТРУКЦІЯ',
  noGroup: 'Без групи',
  time: 'Робочий час',
  monday: 'Понеділок',
  tuesday: 'Вівторок',
  wednesday: 'Середа',
  thursday: 'Четвер',
  friday: 'Пʼятниця',
  saturday: 'Субота',
  sunday: 'Неділя',
  sleepOn: 'Увімкнення',
  sleepOff: 'Вимкнення',
  shutDown: 'Вимкнути',
  online: 'В мережі',
  lastUpdate: 'Останнє оновлення',
  absent: 'Відсутній',
  volume: 'Гучність',
  workingHours: 'Робочі години',
  volumeSchedule: 'Графік гучності',
  addNew: '+ додати',
  days: 'Всі дні',
  hours: 'Години',
};

export default devices;
