import { styled, Box, Typography } from '@mui/material';

export const Wrapper = styled(Box)(({ theme }) => ({
  display: 'flex',
  padding: `${theme.spacing(6)} ${theme.spacing(2.5)} ${theme.spacing(
    6
  )} ${theme.spacing(7.5)}`,
  justifyContent: 'space-between',
  alignItems: 'center',

  '& .MuiIconButton-root svg': {
    fill: theme.palette.text.secondary,
  },
}));

export const Name = styled(Typography)(({ theme }) => ({
  textAlign: 'center',
  color: theme.palette.text.secondary,
  ...theme.typography.body2,

  [theme.breakpoints.up('laptop')]: {
    ...theme.typography.h3,
  },
}));
